<template>
    <el-scrollbar class="eCommerce-container">
        <div class="main-box">
            <div class="tab-title">
                <div class="popover-title">省份分布</div>
            </div>
            <el-table :data="infoDetail.provinces_distribution_data" class="customTable" style="width: 100%; margin-top: 20px;" @sort-change="sortChange">
                <el-table-column label="排名" align="center" width="100px">
                    <template slot-scope="scope">
                        <div class="sort-img" v-if="scope.row.rank < 4">
                            <img v-if="scope.row.rank === 1" :src="require('../../../../assets/images/student/num-01.png')" alt="">
                            <img v-else-if="scope.row.rank === 2" :src="require('../../../../assets/images/student/num-02.png')" alt="">
                            <img v-else :src="require('../../../../assets/images/student/num-03.png')" alt="">
                        </div>
                        <div class="sort-num" v-else>{{scope.row.rank | formatRank}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="省份" prop="province_name"></el-table-column>
                <el-table-column prop="stores_number" label="店铺数" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.stores_number}}
                    </template>
                </el-table-column>
                <el-table-column prop="stores_number" label="店铺占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.stores_number_rate}}
                    </template>
                </el-table-column>
                <el-table-column prop="sale_stores_number" label="动销店铺数" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.sale_stores_number}}
                    </template>
                </el-table-column>
                <el-table-column prop="trading_money" label="成交金额占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.trading_money_rate}}%
                    </template>
                </el-table-column>

                <template slot="empty">
                    <div class="null-data" style="margin-top: 20px">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </template>
            </el-table>
            <el-pagination class="pages-center" style="margin: 20px 0 30px" v-if="infoDetail.provinces_distribution_data.length > 0"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           layout="prev, pager, next, jumper"
                           :total="listPages.total"
                           @current-change="pagesCurrentChange">
            </el-pagination>

            <div class="tab-title" style="margin-top: 50px">
                <div class="popover-title">类目分布 TOP10</div>
            </div>
            <el-table :data="infoDetail.category_distribution_data" class="customTable" style="width: 100%; margin-top: 20px;" @sort-change="sortChangeCate">
                <el-table-column label="子类目">
                    <template slot-scope="scope">
                        <div class="text-overflow-2">{{scope.row.category_name}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="stores_number" label="店铺数" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.stores_number}}
                    </template>
                </el-table-column>
                <el-table-column prop="stores_number" label="店铺占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.stores_number_rate}}
                    </template>
                </el-table-column>
                <el-table-column prop="sale_stores_number" label="动销店铺数" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.sale_stores_number}}
                    </template>
                </el-table-column>
                <el-table-column prop="trading_money" label="成交金额占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.trading_money_rate}}%
                    </template>
                </el-table-column>
                <el-table-column prop="trading_order" label="成交单量占比" align="center" sortable="custom">
                    <template slot-scope="scope">
                        {{scope.row.trading_order_rate}}%
                    </template>
                </el-table-column>

                <template slot="empty">
                    <div class="null-data" style="margin-top: 20px">
                        <div style="display: flex;flex-direction: column;align-items: center;">
                            <img src="../../../../assets/images/student/goods-null-data.png" alt="">
                            <span>暂无数据</span>
                        </div>
                    </div>
                </template>
            </el-table>

            <div class="tab-title" style="margin-top: 50px">
                <div class="popover-title">卖家行为</div>
                <div style="margin-top: 20px">商品上新时间分布</div>
                <div class="data-content" id="goodUpdateTimesData" style="height: 280px"></div>
            </div>
        </div>
    </el-scrollbar>
</template>

<script>
    import { dataCentorTplGetSellerAnalysisExam } from '@/utils/apis.js'
    export default {
        filters: {
            formatRank(val) {
                if (val < 10) {
                    return `0${val}`
                } else {
                    return val
                }
            }
        },
        data() {
            return {
                infoDetail: {
                    provinces_distribution_data: [],
                    category_distribution_data: [],
                    goodupdate_times_distribution_data: []
                },
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },

                provinces_order_name: void 0, // 排序字段
                provinces_order_type: void 0, // 排序方式, asc:升序, desc:降序

                category_order_name: void 0, // 排序字段
                category_order_type: void 0, // 排序方式, asc:升序, desc:降序

                goodUpdateTimesData: null,
            }
        },
        mounted() {
            this.getInfo()
        },
        methods: {
            getInfo() {
                let params = {
                    data_center_tpl_id: this.$route.query.data_center_tpl_id,
                    provinces_paging: 1,
                    provinces_page: this.listPages.currentPageNum,
                    provinces_pageSize: this.listPages.eachPageNum,
                    category_paging: 0,
                    // category_page: this.categoryListPages.currentPageNum,
                    // category_pageSize: this.categoryListPages.eachPageNum,
                }
                if (this.provinces_order_name) {
                    params.provinces_ordering = 1
                    params.provinces_order_name = this.provinces_order_name
                } else {
                    params.provinces_ordering = 0
                }
                if (this.provinces_order_type) {
                    params.provinces_ordering = 1
                    params.provinces_order_type = this.provinces_order_type
                } else {
                    params.provinces_ordering = 0
                }

                if (this.category_order_name) {
                    params.category_ordering = 1
                    params.category_order_name = this.category_order_name
                } else {
                    params.category_ordering = 0
                }
                if (this.category_order_type) {
                    params.category_ordering = 1
                    params.category_order_type = this.category_order_type
                } else {
                    params.category_ordering = 0
                }
                dataCentorTplGetSellerAnalysisExam(params).then((res) => {
                    this.infoDetail.provinces_distribution_data = res.data.provinces_distribution_data.list
                    this.listPages.total = res.data.provinces_distribution_data.total

                    this.infoDetail.category_distribution_data = res.data.category_distribution_data.list

                    this.infoDetail.goodupdate_times_distribution_data = res.data.goodupdate_times_distribution_data.map(item => {
                        return item.good_number
                    })

                    this.goodUpdateTimesData = this.$echarts.init(document.getElementById('goodUpdateTimesData'));

                    let resizeTimer = null;
                    window.onresize = () => {
                        clearTimeout(resizeTimer);
                        resizeTimer = setTimeout(() => {
                            this.goodUpdateTimesData.resize()
                        }, 100)
                    }
                    this.setGoodUpdateTimesData()
                }).catch((err) => {})
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getInfo()
            },
            // 自定义表格数据排序-省份
            sortChange(column, prop, order) {
                this.provinces_order_name = column.prop
                if (column.order === 'ascending') {
                    this.provinces_order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.provinces_order_type = 'desc'
                } else {
                    this.provinces_order_type = void 0
                }
                this.listPages.currentPageNum = 1
                this.getInfo()
            },
            // 自定义表格数据排序-类目
            sortChangeCate(column, prop, order) {
                this.category_order_name = column.prop
                if (column.order === 'ascending') {
                    this.category_order_type = 'asc'
                } else if (column.order === 'descending') {
                    this.category_order_type = 'desc'
                } else {
                    this.category_order_type = void 0
                }
                this.getInfo()
            },

            // 商品上新时间分布 折线图 eCharts
            setGoodUpdateTimesData() {
                this.goodUpdateTimesData.setOption({
                    xAxis: {
                        type: 'category',
                        data: ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'],
                        boundaryGap: false,
                        axisLabel: {
                            textStyle: {
                                color: '#666'
                            },
                            align: 'center'
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                    },
                    yAxis: {
                        type: 'log',
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#666'
                            }
                        },
                        axisLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        },
                        splitLine: {
                            lineStyle: {
                                color: '#F3F6FA'
                            }
                        }
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'cross',
                            label: {
                                backgroundColor: '#6a7985'
                            }
                        },
                        backgroundColor: '#fff',
                        textStyle: {
                            color: '#666',
                        },
                        borderColor: '#DCDCDC',
                        borderWidth: 2,
                        formatter: (params) => {
                            let res = params[0].name;
                            for (let i = 0; i < params.length; i++) {
                                res += `<br/>${params[i].marker}${params[i].seriesName}:${params[i].value}`;
                            }
                            return res
                        },
                    },
                    legend: {
                        icon: 'circle',
                        bottom: 0,
                        selectedMode: false
                    },
                    grid: {
                        top: 20,
                        left: 100,
                        right: 40,
                        bottom: 80,
                    },
                    series: [
                        {
                            name: '商品上新时间分布',
                            type: 'line',
                            data: this.infoDetail.goodupdate_times_distribution_data,
                            smooth: true,
                            symbolSize: 0,
                            itemStyle: {
                                normal: {
                                    color: "#E4393C",
                                }
                            },
                        },
                    ]
                }, true);
            },
        }
    }
</script>

<style scoped lang="scss">
    .eCommerce-container {
        height: 100%;
        ::v-deep > .el-scrollbar__wrap {
            overflow-x: hidden;
        }
    }
    .main-box {
        padding: 20px;
    }
    .tab-title {
        padding-left: 15px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: -2px;
            left: 0;
            width: 5px;
            height: 20px;
            background: #2DC079;
            border-radius: 3px;
        }
        .popover-title {
            font-size: 18px;
            line-height: 1;
            display: inline-block;
        }
    }
    .sort-num {
        display: inline-block;
        background: #E7F6EF;
        height: 24px;
        width: 24px;
        border-radius: 12px;
        line-height: 24px;
        text-align: center;
    }
    .sort-img {
        display: inline-block;
        img {
            display: block;
        }
    }
    .data-content {
        height: 350px;
        width: 100%;
        margin-top: 30px;
    }
</style>